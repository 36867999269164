import './style.sass'
import axios from '@/axios'
import { DefaultTableExposed, TableHeader } from 'apptimizm-ui/src/ui/default-table/default-table'
import { DefaultTable } from 'apptimizm-ui'
import { defineComponent, onMounted, Ref, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { DocumentEntity, DocumentMeta, ScannedProducts, ScannedProductsMeta } from './types'
import moment from 'moment'
import { EditInput } from '@/UI/edit-input'
import { mutations, store } from '@/store'
import AddProduct from './add-product'

export default defineComponent({
  setup () {
    const route = useRoute()
    const router = useRouter()
    const table = ref(null) as unknown as Ref<DefaultTableExposed>
    const documentMeta = new DocumentMeta()
    const scannedProductsMeta = new ScannedProductsMeta()
    const documentItem = ref<DocumentEntity>(new DocumentEntity())
    const showModal = ref<boolean>(false)
    const toggle = () => { showModal.value = !showModal.value }
    const submit = async (item: ScannedProducts, refBtn: Ref<HTMLElement>) => {
      const { amount } = item
      const { errors } = (await axios.patch(
        scannedProductsMeta.endpointPatch + item.id, { amount })
      ).data

      if (errors) {
        mutations.pushNotification('Ошибка сохранения позиций документа', true)
      } else {
        mutations.pushNotification('Позиции документа сохранены')
        refBtn.value.style.opacity = '0'
        refBtn.value.style.visibility = 'hidden'
      }
    }

    const loadDocument = async () => {
      const { data } = await axios.get(documentMeta.endpoint + route.params.id)
      documentItem.value = documentMeta.load(data)
    }

    const deleteProduct = async (item: ScannedProducts) => {
      await axios.post(documentMeta.endpoint + documentItem.value.id + '/delete-product-position/', {
        product: item.id
      })
      table.value.reload()
    }

    watch(route, async () => {
      if (route.name !== 'documentation-id') return
      table.value && table.value.reload()
      loadDocument()
    })

    onMounted(async () => {
      if (!store.currentProject.id) router.push({ name: 'documentation' })
      else {
        await loadDocument()
      }
    })

    const headers: TableHeader[] = [
      { name: 'Номер' },
      { name: 'Название товара', minWidth: '280px' },
      { name: 'Код товара', minWidth: '240px' },
      { name: 'Штрихкод', minWidth: '200px' },
      { name: 'Датаматрикс', minWidth: '200px' },
      { name: 'Количество', minWidth: '200px' },
      { name: 'Время добавления позиции', minWidth: '240px' },
      { name: '', minWidth: '50px' },
      { name: '', minWidth: '25px' }
    ]

    const line = (item: ScannedProducts) => {
      const refBtn = ref(item.id) as unknown as Ref<HTMLElement>
      const onChangeValue = (item: ScannedProducts, model: 'amount', value: number): void => {
        item[model] = value
        refBtn.value.style.opacity = '1'
        refBtn.value.style.visibility = 'visible'
      }

      return (
        <tr>
          <td>{item.number}</td>
          <td>{item.product.title}</td>
          <td>{item.product.vendorCode}</td>
          <td>{item.product.barcode}</td>
          <td>{(item.dm ? item.dm : item.product.dm) || ''}</td>
          <td>
            <EditInput
              modelValue={item.amount}
              onValueChange={(v: number) => { return onChangeValue(item, 'amount', v) }}
              onValueClear={() => { onChangeValue(item, 'amount', 0) }}
            />
          </td>
          <td>{moment(item.scannedTime).format('DD.MM.YYYY HH:mm:ss')}</td>
          <td>
            <button ref={refBtn} onClick={() => submit(item, refBtn)} class="button-string-edit">Готово</button>
          </td>
          <td style="display: flex; height: 100%;">
            <button onClick={(e: Event) => deleteProduct(item)} class="button-delete-project">
              <img src={require('../../assets/image/delete-project.svg')}/>
            </button>
          </td>
        </tr>
      )
    }

    return (): JSX.Element => (
      <div class="layout position-page">
        <div class='flexbar'>
          <div class="caption">
            <img class="close-svg" src={require('../../assets/image/close.svg')} alt="close" onClick={() => router.push({ name: 'documentation' })}/>
            <h1>Позиции документа №{documentItem.value.fakeId}</h1>
          </div>
          <button disabled={!store.currentProject.id} onClick={toggle} class='button-add'>Добавить</button>
        </div>
        <div class='table-wrapper lock-table-overflow'>
          <DefaultTable
            key={documentMeta.endpoint + route.params.id + '/scanned-products/'}
            axios={axios}
            endpoint={documentMeta.endpoint + route.params.id + '/scanned-products/'}
            ref={table}
            line={line}
            headers={headers}
            itemConverter={(v: ScannedProductsMeta) => ScannedProductsMeta.load(v)}
            responseItemsKey="results"
            responseTotalKey="count"
            paginationType="page"
            perPage={20}
          />
        </div>
        {showModal.value && <AddProduct documentId={documentItem.value.id} toggle={toggle} reload={table.value.reload}/>}
      </div>
    )
  }
})
